import { Button, Modal, Form, message, Row, Col, Input } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/AuthContext";
import { handleReverseEnrollment } from "../../../../api/enrollments";

export default function ReverseEnroll({
  selected,
  isVisible,
  setVisible,
  pagination,
}: any) {
  const { admin } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isPending } = useMutation({
    mutationFn: handleReverseEnrollment,
    onSuccess: () => {
      message.success("Reversed Successfully");
      form.resetFields();
      queryClient.invalidateQueries({
        queryKey: ["enrollments", pagination.page, pagination.limit],
      });
      setVisible(false);
    },
    onError: (error) => {
      message.error(`${error}`);
    },
  });

  const onSubmit = async () => {
    const { password } = await form.getFieldsValue();
    const data = {
      email: admin?.email,
      password,
    };
    await mutate({ _id: selected?._id, data });
  };

  return (
    <Modal
      open={isVisible}
      title="Reverse The Enrollment"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="reverseEnrollment"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isPending}
          danger
        >
          Reverse
        </Button>,
      ]}
    >
      <Form
        id="reverseEnrollment"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="flex flex-col items-center mb-6">
          <div className="text-lg font-bold">{selected?.quiz?.title}</div>
          <div className="">
            Are you sure you want to
            <span className="text-red-600 font-bold mx-1">Reverse</span>the
            Enrollment for
          </div>
          <div className="text-red-600 font-bold text-2xl">
            {selected?.user?.name}
          </div>

          <div className="italic text-red-500">
            Note: This will delete result for this quiz as well.
          </div>
        </div>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="password"
              label={"Enter Password to Reverse Enrollment"}
              rules={[
                {
                  required: true,
                  message: "Please enter password",
                },
              ]}
            >
              <Input type="password" placeholder="Password" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
