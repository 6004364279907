import { Pagination, Tooltip, message } from "antd";
import { useState } from "react";
import { RiFileExcel2Line } from "react-icons/ri";
import { ExportToExcel } from "../../../utils/exportExcel";

import CreateQuiz from "./components/CreateQuiz";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Something from "../../../components/shared/Something";
import BackNav from "../../../components/toolbars/BackNav";
import { handleFetchQuizzes, handleShowQuiz } from "../../../api/quizzes";
import QuizWidget from "./components/QuizWidget";
import UploadQuizThumb from "./components/UploadQuizThumb";
import NoResult from "../../../components/shared/NoResult";

export default function Quizzes() {
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [uploadModal, setUploadModal] = useState<boolean>(false);
  const [selectedQuiz, setSelectedQuiz] = useState<any>(null);
  const queryClient = useQueryClient();

  const [pagination, setPagination] = useState<any>({
    page: 1,
    per_page: 20,
    total_pages: 1,
    total_count: 0,
  });

  const {
    data: quizzes,
    refetch,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["quizzes"],
    queryFn: handleFetchQuizzes,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: handleShowQuiz,
    onSuccess: () => {
      message.success("Updated Successfully");
      queryClient.invalidateQueries({ queryKey: ["quizzes"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const handleShow = (quiz: any) => {
    mutate({ data: {}, _id: quiz?._id });
  };

  const handleRefetch = () => {
    refetch();
  };

  const handleThumbnail = (item: any) => {
    setSelectedQuiz(item);
    setUploadModal(true);
  };

  return (
    <div className="bg-gray-100">
      <BackNav link={`/admin`} />
      <div className="bg-white m-1">
        <div className="flex justify-between border-b p-2">
          <div className="">
            <div className="font-bold">Quizzes</div>
            <div className="text-gray-600">Manage Quizzes Details</div>
          </div>

          <div className="flex">
            <Tooltip title="Export To Excel">
              <button
                color="#E50000"
                className="flex mx-2 justify-center items-center border px-4 py-1 h-10 hover:bg-gray-100"
                onClick={() =>
                  ExportToExcel(
                    quizzes?.map((item: any, index: number) => {
                      return {
                        "S/N": index + 1,
                        Title: item?.title || "-",
                        "Start Date": item?.startDate || "-",
                        "End Date": item?.endDate || "-",
                        Description: item?.description || "-",
                        Status: item?.status || "-",
                      };
                    }),
                    "Quizzes.xlsx"
                  )
                }
              >
                <RiFileExcel2Line size={20} />
              </button>
            </Tooltip>
            <button
              onClick={() => setCreateModal(true)}
              className="flex mx-2 justify-center items-center border px-4 py-1 h-10 hover:bg-gray-100"
            >
              + New
            </button>
          </div>
        </div>
        {isError ? (
          <Something refetch={refetch} isFetching={isFetching} />
        ) : (
          <div className="p-4">
            {quizzes?.length === 0 ? (
              <NoResult title="No Quizzes Found" />
            ) : (
              <div className="">
                {quizzes?.map((item: any, index: number) => (
                  <QuizWidget
                    item={item}
                    index={index}
                    key={index}
                    handleShow={handleShow}
                    handleThumbnail={handleThumbnail}
                  />
                ))}
                <div className="flex justify-end">
                  <Pagination defaultCurrent={1} total={4} />
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <CreateQuiz
        isVisible={createModal}
        setVisible={setCreateModal}
        refetch={handleRefetch}
      />

      <UploadQuizThumb
        id={selectedQuiz?._id}
        isVisible={uploadModal}
        setVisible={setUploadModal}
      />
    </div>
  );
}
