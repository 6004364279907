import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import {  useParams } from "react-router";
import { FaCheck } from "react-icons/fa";

import { handleFetchActiveQuiz, handleFinishQuiz } from "../../api/quizzes";
import placeholder from "../../assets/images/placeholder.png";
import QuizHeader from "../../layouts/components/QuizHeader";
import { AuthContext } from "../../contexts/AuthContext";
import { message } from "antd";
import ScoreWidget from "../../components/shared/ScoreWidget";
interface IChoice {
  choice: string;
  _id: string;
  isCorrect?: boolean;
}
interface IQuestion {
  question: string;
  hint: string;
  choices: IChoice[];
  thumbnail: string;
  _id: string;
}

export default function ActiveQuiz() {
  const { user } = useContext(AuthContext);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const { id } = useParams();
  const [error, setError] = useState<any>(null);
  const queryClient = useQueryClient();
  const [result, setResult] = useState<any>({});
  const [isProgress, setProgress] = useState<boolean>(true);

  const { data: quiz } = useQuery({
    queryKey: [id, "activeQuizz"],
    queryFn: () => handleFetchActiveQuiz(id),
    retry: 3,
  });

  const [questions, setQuestions] = useState<IQuestion[]>(
    quiz?.questions || []
  );

  const { mutate, isPending } = useMutation({
    mutationFn: handleFinishQuiz,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["quizzes"] });
      queryClient.invalidateQueries({ queryKey: [id, "quizzes"] });
      setResult(data);
    
    },
    onError: (error) => {
      message.error(`${error}`);
    },
  });

  const handleNextButtonClick = () => {
    const question = questions[currentQuestion];
    question?.choices?.map((item: any) => {
      if (item.hasOwnProperty("isCorrect") === false) {
        setError("Please select an answer to proceed.");
      } else {
        const nextQuestion = currentQuestion + 1;
        if (nextQuestion < questions.length) {
          setCurrentQuestion(nextQuestion);
        }
      }
    });
  };
  const handleSelectAnswer = (questionId: string, choiceId: string) => {
    setError(null);
    setQuestions((prevState) =>
      prevState?.map((question: IQuestion) => {
        if (question._id === questionId) {
          return {
            ...question,
            choiceId: choiceId,
            choices: question.choices.map((choice: IChoice) =>
              choice._id === choiceId
                ? { ...choice, isCorrect: true }
                : { ...choice, isCorrect: false }
            ),
          };
        }
        return question;
      })
    );
  };
  const handleChoiceClick = (questionId: string, choiceId: string) => {
    handleSelectAnswer(questionId, choiceId);
  };
  const handlePrevButtonClick = () => {
    setError(null);
    const prevQuestion = currentQuestion - 1;
    if (prevQuestion >= 0) {
      setCurrentQuestion(prevQuestion);
    }
  };

  const handleCompleteQuiz = async () => {
    setProgress(false);
    await mutate({
      _id: quiz?._id,
      data: { answers: questions, userId: user?._id },
    });
  };

  const progress = ((currentQuestion + 1) / questions?.length) * 100;

  useEffect(() => {
    setQuestions(quiz?.questions || []);
  }, [quiz]);

  return (
    <div className="relative w-full h-screen overflow-hidden">
      <video
        className="absolute top-0 left-0 w-full h-full object-cover"
        src="https://ik.imagekit.io/ttltz/cybersecurity/quiz/video_5fSvRpaLu.mp4?updatedAt=1718008696191"
        autoPlay
        loop
        muted
      />
      <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center">
        {isProgress ? (
          <>
            <QuizHeader user={user} />

            <div className=" bg-white flex items-center justify-center w-1/2">
              <div className="w-full mx-auto p-4">
                <div className="relative w-full h-2 bg-gray-200 rounded">
                  <div
                    className="absolute top-0 h-2 bg-blue-600 rounded"
                    style={{ width: `${progress}%` }}
                  ></div>
                </div>
                <div className="flex justify-center items-center">
                  <div className="text-gray-500 mt-4">
                    Question {currentQuestion + 1} / {questions?.length}
                  </div>
                </div>

                <div className="mt-4">
                  <img
                    src={questions[currentQuestion]?.thumbnail || placeholder}
                    alt=""
                    height={400}
                    width={600}
                    className="w-full aspect-[5/3] object-fill"
                  />
                  <h2 className="text-2xl font-bold mb-4">
                    {questions[currentQuestion]?.question}
                  </h2>
                  <div className="">
                    {error && (
                      <div className="text-red-600 bg-red-100 px-2 py-1 mx-2 mb-2">
                        {error}
                      </div>
                    )}
                  </div>
                  <div className="flex w-full">
                    {questions[currentQuestion]?.choices?.map(
                      (item: any, index: number) => (
                        <button
                          key={index}
                          onClick={() =>
                            handleChoiceClick(
                              questions[currentQuestion]?._id,
                              item?._id
                            )
                          }
                          className={`w-1/2 flex justify-between items-center px-1 py-1 mx-2 border rounded text-white hover:bg-green-600 transition duration-200 ${
                            item?.isCorrect ? "bg-green-500" : "bg-gray-400"
                          }`}
                        >
                          <div className="w-6 font-bold">
                            {index === 0 ? "A" : "B"}
                          </div>
                          <div className="bg-white rounded text-black flex-1 py-1">
                            {item?.choice}
                          </div>
                          {item?.isCorrect ? (
                            <div className="w-6 flex justify-center">
                              <FaCheck />
                            </div>
                          ) : null}
                        </button>
                      )
                    )}
                  </div>
                  <div className="flex justify-between mt-2 border-t-2 border-gray-400 pt-4">
                    <button
                      onClick={handlePrevButtonClick}
                      disabled={currentQuestion === 0}
                      className={`px-4 py-2 bg-gray-500 text-white rounded w-40 ${
                        currentQuestion === 0
                          ? "opacity-20 cursor-not-allowed"
                          : "hover:bg-gray-700"
                      } transition duration-200`}
                    >
                      Previous
                    </button>
                    {currentQuestion === questions?.length - 1 ? (
                      <button
                        onClick={() => handleCompleteQuiz()}
                        className={`px-4 py-2 bg-fish text-white rounded transition duration-200 w-40`}
                      >
                        {isPending ? "Submitting" : "Finish"}
                      </button>
                    ) : (
                      <button
                        onClick={handleNextButtonClick}
                        className={`px-4 py-2 bg-fish text-white rounded  transition duration-200 w-40`}
                      >
                        Next
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <ScoreWidget
            user={user}
            result={result}
            isPending={isPending}
            resend={handleCompleteQuiz}
          />
        )}
      </div>
    </div>
  );
}
