import { IoLogOutOutline } from "react-icons/io5";
import { IoIosArrowBack } from "react-icons/io";


import logo from "../../assets/images/kg_logo_white.png";

import { Link } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";

export default function Header() {
  const { signoutAdmin } = useContext(AuthContext);
  return (
    <div className="h-16 bg-fish flex justify-between items-center px-2">
      <Link to={"/"} className="flex items-center justify-center">
        <div className="flex items-center">
          <img src={logo} alt="" width={50} />
          <div className="hidden sm:block text-white text-2xl font-bold">
            KARIMJEE
          </div>
        </div>
        <div className="border-l text-white text-2xl mx-2 px-2 font-thin">
          CYBER
        </div>
      </Link>
      <div className="flex text-white text-3xl"></div>
      <div className="flex items-center">
        <Link to={"/"} className="flex  items-center text-white">
          <IoIosArrowBack size={24} />
          <div className="mr-4">Go to Quiz</div>
        </Link>
        <button onClick={() => signoutAdmin()} className="border-l pl-2">
          <IoLogOutOutline color="#FFFFFF" size={28} />
        </button>
      </div>
    </div>
  );
}
