import { Button, Modal, Form, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleDeleteQuiz } from "../../../../api/quizzes";
import { useNavigate } from "react-router";

export default function DeleteQuiz({ quiz, isVisible, setVisible }: any) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { mutate, isPending } = useMutation({
    mutationFn: handleDeleteQuiz,
    onSuccess: () => {
      message.success("Deleted Successfully");
      queryClient.invalidateQueries({ queryKey: ["quizzes"] });
      navigate("/admin/quizzes");
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    await mutate({ _id: quiz?._id });
  };

  return (
    <Modal
      open={isVisible}
      title="Delete Quiz"
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" danger onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="deleteQuiz"
          htmlType="submit"
          type="primary"
          danger
          onSubmit={onSubmit}
          loading={isPending}
        >
          Delete
        </Button>,
      ]}
    >
      <Form
        id="deleteQuiz"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="flex flex-col items-center">
          <div className="">
            Do you want to{" "}
            <span className="font-bold text-red-500 mx-1">Delete</span>
            Quiz
          </div>
          <div className="font-bold">{quiz?.title}</div>
        </div>
      </Form>
    </Modal>
  );
}
