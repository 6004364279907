import { Button, Modal, Form, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleDeleteQues } from "../../../../api/quizzes";

export default function DeleteQues({ id, ques, isVisible, setVisible }: any) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isPending } = useMutation({
    mutationFn: handleDeleteQues,
    onSuccess: () => {
      message.success("Deleted Successfully");
      queryClient.invalidateQueries({ queryKey: [id, "quizzes"] });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    await mutate({ _id: id, questionId: ques?._id });
  };

  return (
    <Modal
      open={isVisible}
      title="Delete Question"
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" danger onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="deleteQues"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isPending}
          danger
        >
          Delete
        </Button>,
      ]}
    >
      <Form
        id="deleteQues"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="flex flex-col items-center">
          <div className="">
            Do you want to{" "}
            <span className="font-bold text-red-500 mx-1">Delete</span>
            Question
          </div>
          <div className="font-bold">{ques?.question}</div>
        </div>
      </Form>
    </Modal>
  );
}
