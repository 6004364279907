import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { LuFileClock } from "react-icons/lu";

import { handleFetchEnrollmentsCount } from "../../api/enrollments";

export default function ManageEnrol() {
  const { data: count } = useQuery({
    queryKey: ["enrollCount"],
    queryFn: handleFetchEnrollmentsCount,
  });
  return (
    <Link
      to={`/admin/enrollments`}
      className={`col-span-6 sm:col-span-4 lg:col-span-3 bg-white p-4 rounded-md mx-1 mt-1 sm:mx-1 sm:my-1`}
      style={{
        backgroundColor: "#988BEB",
        color: "#FFFFFF",
      }}
    >
      <div className="flex items-center justify-center">
      <div className="text-3xl sm:text-6xl border-r font-bold mx-2 sm:mx-4 px-2 sm:px-4">{count}</div>
        <div className="flex flex-col justify-center items-center">
          <div className="">
            <LuFileClock color="#ffffff" size={40} />
          </div>
          <div className="">Enrollments</div>
        </div>
      </div>
    </Link>
  );
}
