import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import patchData from "../services/patchData";
import postData from "../services/postData";

const handleFetchQuizzes = async () => {
  const uri = `v1/quizzes`;
  const response = fetchData({ uri });
  return response;
};

const handleFetchQuiz = async (id: any) => {
  const uri = `v1/quizzes/${id}`;
  const response = fetchData({ uri });
  return response;
};


const handleFetchQuizCount = async () => {
  const uri = `v1/quizzes/count/all`;
  const response = fetchData({ uri });
  return response;
};

const handleFetchActiveQuiz = async (id: any) => {
  const uri = `v1/quizzes/${id}/active`;
  const response = fetchData({ uri });
  return response;
};

const handleFetchQuizEnrollments = async (id: any) => {
  const uri = `v1/quizzes/${id}/enrollments`;
  const response = fetchData({ uri });
  return response;
};

const handleShowedQuiz = async () => {
  const uri = `v1/quizzes/showed/quiz`;
  const response = fetchData({ uri });
  return response;
};

const handleCreateQuiz = async (data: any) => {
  const uri = `v1/quizzes`;
  const response = postData({ data, uri });
  return response;
};

const handleStartQuiz = async ({ _id, data }: any) => {
  const uri = `v1/quizzes/${_id}/start`;
  const response = postData({ data, uri });
  return response;
};

const handleSendEmail = async ({ data }: any) => {
  const uri = `v1/quizzes/send/email`;
  const response = postData({ data, uri });
  return response;
};

const handleShowQuiz = async ({ _id, data }: any) => {
  const uri = `v1/quizzes/${_id}/show`;
  const response = patchData({ data, uri });
  return response;
};

const handleUpdateQuiz = async ({ _id, data }: any) => {
  const uri = `v1/quizzes/${_id}`;
  const response = patchData({ data, uri });
  return response;
};


const handleFinishQuiz = async ({ _id, data }: any) => {
  const uri = `v1/quizzes/${_id}/finish`;
  const response = patchData({ data, uri });
  return response;
};

const handleUploadQuizThumbnail = async ({ _id, data }: any) => {
  const uri = `v1/quizzes/${_id}/thumbnail/upload`;
  const response = postData({ data, uri });
  return response;
};

const handleDeleteQuiz = async ({ _id }: any) => {
  const uri = `v1/quizzes/${_id}`;
  const response = deleteData({ uri });
  return response;
};

const handleCreateQuestion = async ({ _id, data }: any) => {
  const uri = `v1/quizzes/${_id}/questions`;
  const response = postData({ data, uri });
  return response;
};

const handleUpdateQues = async ({ _id, questionId, data }: any) => {
  const uri = `v1/quizzes/${_id}/questions/${questionId}`;
  const response = patchData({ data, uri });
  return response;
};

const handleDeleteQues = async ({ _id, questionId, data }: any) => {
  const uri = `v1/quizzes/${_id}/questions/${questionId}`;
  const response = deleteData({ data, uri });
  return response;
};

const handleUploadQuesThumbnail = async ({ _id, questionId, data }: any) => {
  const uri = `v1/quizzes/${_id}/questions/${questionId}/thumbnail/upload`;
  const response = postData({ data, uri });
  return response;
};

export {
  handleCreateQuiz,
  handleFetchQuizzes,
  handleShowedQuiz,
  handleFetchQuiz,
  handleShowQuiz,
  handleStartQuiz,
  handleFinishQuiz,
  handleUpdateQuiz,
  handleDeleteQuiz,
  handleFetchQuizCount,
  handleUploadQuesThumbnail,
  handleUploadQuizThumbnail,
  handleFetchQuizEnrollments,
  handleFetchActiveQuiz,
  handleSendEmail,
  handleCreateQuestion,
  handleDeleteQues,
  handleUpdateQues
};
