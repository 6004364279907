import { Outlet } from "react-router";
import Header from "./components/Header";

export default function MainLayout() {
  return (
    <div className="flex bg-gray-100 min-h-screen font-fish">
      <div className="flex-grow overflow-x-auto">
        <Header />
        <Outlet />
      </div>
    </div>
  );
}
