import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

/*
|---------------------------------------------------------------------
|                             Routes
|---------------------------------------------------------------------
*/

import { AuthContext, AuthProvider } from "./contexts/AuthContext";
import Enrollments from "./pages/manage/enrollments/Enrollments";
import MainLayout from "./layouts/MainLayout";
import NotFound from "./pages/redirects/NotFound";
import Forgot from "./pages/auth/Forgot";
import Reset from "./pages/auth/Reset";
import Denied from "./pages/auth/Denied";
import AuthLayout from "./layouts/AuthLayout";
import Manage from "./pages/manage/Manage";
import Users from "./pages/manage/users/Users";
import MyProfile from "./pages/profile/MyProfile";
import HomeLayout from "./layouts/HomeLayout";
import Welcome from "./pages/home/Welcome";
import Signin from "./pages/auth/Signin";
import Quizzes from "./pages/manage/quizzes/Quizzes";
import Results from "./pages/manage/results/Results";
import AResult from "./pages/manage/results/Result";
import Quiz from "./pages/manage/quizzes/Quiz";
import ActiveQuiz from "./pages/home/ActiveQuiz";
import Result from "./pages/results/Result";

function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Routes>
          <Route element={<AuthLayout />}>
            <Route path="/signin" element={<Signin />} />
            <Route path="/forgot" element={<Forgot />} />
            <Route path="/reset" element={<Reset />} />
            <Route path="/denied" element={<Denied />} />
          </Route>
          <Route element={<HomeLayout />}>
            <Route path="/" element={<Welcome />} />
            <Route path="/quiz/:id" element={<ActiveQuiz />} />
            <Route path="/results/:id" element={<Result />} />
          </Route>
          <Route element={<MainLayout />}>
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <MyProfile />
                </ProtectedRoute>
              }
            />

            <Route
              path="/admin"
              element={
                <ProtectedRoute>
                  <Manage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/quizzes"
              element={
                <ProtectedRoute>
                  <Quizzes />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/quizzes/:id"
              element={
                <ProtectedRoute>
                  <Quiz />
                </ProtectedRoute>
              }
            />

            <Route
              path="/admin/users"
              element={
                <ProtectedRoute>
                  <Users />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/enrollments"
              element={
                <ProtectedRoute>
                  <Enrollments />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/results"
              element={
                <ProtectedRoute>
                  <Results />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/results/:id"
              element={
                <ProtectedRoute>
                  <AResult />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthProvider>
    </QueryClientProvider>
  );
}

function ProtectedRoute({ children }: { children: JSX.Element }) {
  let { admin } = React.useContext(AuthContext);
  let location = useLocation();
  if (!admin) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }
  return children;
}

export default App;
