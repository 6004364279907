import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { SlRefresh } from "react-icons/sl";
import ScorePercent from "../../../components/shared/ScorePercent";
import ScoreAnswers from "../../../components/shared/ScoreAnswers";
import { useQuery } from "@tanstack/react-query";
import { handleFetchResult } from "../../../api/results";
import Something from "../../../components/shared/Something";
import BackNav from "../../../components/toolbars/BackNav";

export default function Result() {
  const { id } = useParams();

  const {
    data: result,
    isPending,
    refetch,
    isFetching,
    isError,
  } = useQuery({
    queryKey: [id, "result"],
    queryFn: () => handleFetchResult(id),
    retry: 3,
  });

  let pathColor = "#4caf50";
  let message =
    "You did it! You have just aced your latest quiz, and we are thrilled to celebrate your achievement.";

  if (result?.score < 25 || result?.score === undefined) {
    pathColor = "#f44336";
    message =
      "Great effort! You've completed the quiz, and we're proud of your progress. Keep up the hard work!";
  } else if (result?.score < 50) {
    pathColor = "#ffc107";
    message =
      "Don't be discouraged! You've taken an important step by completing the quiz. Keep practicing, and you'll improve in no time.";
  }

  useEffect(() => {}, [result]);

  return (
    <div className="bg-gray-100">
      <BackNav link={`/admin`} />
      <div className="bg-white m-1">
        <div className="flex justify-between border-b p-2">
          <div className="">
            <div className="font-bold">Results</div>
            <div className="text-gray-600">Manage Results Details</div>
          </div>
        </div>
        {isError ? (
          <Something refetch={refetch} isFetching={isFetching} />
        ) : (
          <div className="flex justify-center items-center h-full">
            <div className="bg-white px-4 py-10 rounded-xl w-1/3 flex flex-col items-center justify-center">
              {isPending ? (
                <div className="flex flex-col">
                  <div className="py-16 flex justify-center items-center">
                    <div className="flex flex-col items-center ">
                      <SlRefresh className="animate-spin text-3xl" /> Loading
                      Results ...
                    </div>
                  </div>
                </div>
              ) : (
                <div className="w-full">
                  {isError ? (
                    <Something refetch={refetch} isFetching={isFetching} />
                  ) : (
                    <div className="flex flex-col justify-center items-center w-full">
                      <div className="mb-6 flex flex-col justify-center items-center w-full">
                        <ScorePercent
                          score={result?.score || 0}
                          pathColor={pathColor}
                        />
                        <div className="font-bold text-fish text-3xl">
                          {result?.user?.name}
                        </div>
                        <div className="font-bold text-center text-lg">
                          The Total Score is {result?.score}%.
                        </div>

                        <ScoreAnswers
                          result={result}
                          user={result?.user}
                          isImediate={false}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
