import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  Input,
  message,
  DatePicker,
} from "antd";
import { useEffect, useState } from "react";
import { BsCheck2Circle } from "react-icons/bs";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import getBase64 from "../../../../utils/getBase64";
import { handleUpdateQuiz } from "../../../../api/quizzes";
import TextArea from "antd/es/input/TextArea";

export default function UpdateQuiz({ isVisible, setVisible, quiz }: any) {
  const queryClient = useQueryClient();
  const [isSelected, setIsSelected] = useState(false);
  const [selectedFile, setSelectedFile]: any = useState(null);
  const [form] = Form.useForm();

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const { mutate, isPending } = useMutation({
    mutationFn: handleUpdateQuiz,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      setSelectedFile(null);
      setIsSelected(false);
      queryClient.invalidateQueries({ queryKey: [quiz?._id, "quizzes"] });
      queryClient.invalidateQueries({ queryKey: ["quizzes"] });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    let base64,
      type,
      size = null;

    if (selectedFile) {
      base64 = await getBase64(selectedFile);
      type = selectedFile?.type?.slice(selectedFile?.type - 3);
      size = selectedFile?.size;
    }

    const { title, description, startDate, endDate } =
      await form.getFieldsValue();
    const data = {
      title,
      description,
      startDate,
      endDate,
      thumbnail: base64,
    };

    await mutate({ _id: quiz?._id, data });
  };

  const clearForm = async () => {
    form.resetFields();
    setSelectedFile(null);
    setIsSelected(false);
  };

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({ title: quiz?.title });
    form.setFieldsValue({ description: quiz?.description });
  }, [quiz]);

  return (
    <Modal
      open={isVisible}
      title="Update Quiz"
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="updateQuiz"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isPending}
        >
          Update
        </Button>,
      ]}
    >
      <Form
        id="updateQuiz"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="title"
              label={"Title"}
              rules={[
                {
                  required: true,
                  message: "Please enter title",
                },
              ]}
            >
              <Input type="text" placeholder="Title" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="startDate"
              label={"Start Date"}
              rules={[
                {
                  required: false,
                  message: "Please enter start date",
                },
              ]}
            >
              <DatePicker
                format={"DD-MM-YYYY"}
                placeholder="Start Date"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="endDate"
              label={"End Date"}
              rules={[
                {
                  required: false,
                  message: "Please enter end date",
                },
              ]}
            >
              <DatePicker
                format={"DD-MM-YYYY"}
                placeholder="End Date"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="description"
              label={"Description"}
              rules={[
                {
                  required: true,
                  message: "Please enter description",
                },
              ]}
            >
              <TextArea placeholder="Description" />
            </Form.Item>
          </Col>
        </Row>

        <div className="flex flex-col justify-center items-center bg-gray-100 h-32 rounded-md border">
          <div className="flex flex-col justify-center items-center">
            {isSelected ? (
              <div className="flex flex-col justify-center items-center">
                <BsCheck2Circle color={"#16a34a"} size={30} />
                <div className="text-gray-600 mt-2">Image Selected</div>
                <div className="text-gray-500">{selectedFile?.name}</div>
              </div>
            ) : (
              <Form.Item
                name="file"
                label="Upload Thumbnail ( Optional )"
                rules={[
                  {
                    required: false,
                    message: "Please select file",
                  },
                ]}
              >
                <Input
                  type="file"
                  placeholder="Thumbnail"
                  onChange={changeHandler}
                />
              </Form.Item>
            )}
          </div>
        </div>
      </Form>
    </Modal>
  );
}
