import { useQuery } from "@tanstack/react-query";
import { IoCheckmarkDone } from "react-icons/io5";
import { CiNoWaitingSign } from "react-icons/ci";

import { handleFetchQuizEnrollments } from "../../../../api/quizzes";

export default function Enrollments({ id }: any) {
  const { data: enrollments } = useQuery({
    queryKey: [id, "enrollments"],
    queryFn: () => handleFetchQuizEnrollments(id),
    enabled: !!id,
  });
  return (
    <div className="">
      <div className="font-bold mb-2">ENROLLMENTS : {enrollments?.length}</div>
      <div className="border">
        <div className="flex justify-between border-b-2  font-bold">
          <div className="flex items-center flex-1">
            <div className="w-24 p-2 border-r">S/N.</div>

            <div className="w-64 p-2 border-r">NAME</div>
            <div className="w-64 p-2 border-r">EMAIL</div>
            <div className="w-32 p-2 border-r">STATE</div>
          </div>
          <div className="w-32 flex items-center">STATUS</div>
        </div>
        {enrollments?.map((item: any, index: number) => (
          <div className="flex justify-between ">
            <div className="flex items-center flex-1">
              <div className="w-24 px-2 py-1 border-r">{index + 1}.</div>
              <div className="w-64 px-2 py-1 border-r">{item?.user?.name}</div>
              <div className="w-64 px-2 py-1 border-r">{item?.user?.email}</div>
              <div className="w-32 px-2 py-1 border-r">{item?.status}</div>
            </div>
            <div className="w-32">
              {item?.isClosed ? (
                <div className="flex items-center">
                  <IoCheckmarkDone color="#16a34a" />
                  <span className="mx-2">Finished</span>
                </div>
              ) : (
                <div className="flex items-center">
                  <CiNoWaitingSign color="#dc2626" />
                  <span className="mx-2">Not Finished</span>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
