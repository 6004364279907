import MainChart from "./reports/MainChart";
import ManageQuiz from "../../components/manage/ManageQuiz";
import ManageUsers from "../../components/manage/ManageUsers";
import ManageEnrol from "../../components/manage/ManageEnrol";
import ManageResults from "../../components/manage/ManageResults";

export default function Manage() {
  return (
    <div className="">
      <div className="m-1">
        <div className="grid grid-cols-12 gap-1">
          <ManageQuiz />
          <ManageUsers />
          <ManageEnrol />
          <ManageResults />
        </div>
        <div className="grid grid-cols-12">
          <div className="col-span-12">
            <div className="" style={{ height: 600 }}>
              <MainChart />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
