import fetchData from "../services/fetchData";
import postData from "../services/postData";

const handleFetchEnrollments = async ({ page, limit }: any) => {
  const uri = `v1/enrollments?page=${page}&limit=${limit}`;
  const response = fetchData({ uri });
  return response;
};

const handleFetchEnrollmentsCount = async () => {
  const uri = `v1/enrollments/count/all`;
  const response = fetchData({ uri });
  return response;
};

const handleReverseEnrollment = async ({ _id, data }: any) => {
  const uri = `v1/enrollments/${_id}/reverse`;
  const response = postData({ uri, data });
  return response;
};

export {
  handleFetchEnrollments,
  handleFetchEnrollmentsCount,
  handleReverseEnrollment,
};
