import { useQuery } from "@tanstack/react-query";
import { LuFileEdit } from "react-icons/lu";
import { Link } from "react-router-dom";
import { handleFetchQuizCount } from "../../api/quizzes";

export default function ManageQuiz() {
  const { data: count } = useQuery({
    queryKey: ["quizCount"],
    queryFn: handleFetchQuizCount,
  });
  return (
    <Link
      to={`/admin/quizzes`}
      className={`col-span-6 sm:col-span-4 lg:col-span-3 bg-white p-4 rounded-md mx-1 mt-1 sm:mx-1 sm:my-1`}
      style={{
        backgroundColor: "#5BBC8F",
        color: "#FFFFFF",
      }}
    >
      <div className="flex items-center justify-center">
        <div className="text-2xl sm:text-6xl border-r font-bold mx-4 px-4">{count}</div>

        <div className="flex flex-col justify-center items-center">
          <div className="">
            <LuFileEdit color="#ffffff" size={40} />
          </div>
          <div className="">Quizzes</div>
        </div>
      </div>
    </Link>
  );
}
