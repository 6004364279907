import { useEffect, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";

import { FaCheck } from "react-icons/fa";

import placeholder from "../../assets/images/placeholder.png";
import ScoreWidget from "../../components/shared/ScoreWidget";
interface IChoice {
  choice: string;
  _id: string;
  isCorrect?: boolean;
}
interface IQuestion {
  question: string;
  hint: string;
  isCorrect: boolean;
  choices: IChoice[];
  choice: string;
  thumbnail: string;
  _id: string;
}

export default function ScoreAnswers({ result, user, isImediate }: any) {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [isProgress, setProgress] = useState<boolean>(true);

  const [answers, setAnswers] = useState<IQuestion[]>(result?.answers || []);

  const handleNextButtonClick = () => {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < answers.length) {
      setCurrentQuestion(nextQuestion);
    }
  };

  const handlePrevButtonClick = () => {
    const prevQuestion = currentQuestion - 1;
    if (prevQuestion >= 0) {
      setCurrentQuestion(prevQuestion);
    }
  };

  const progress = ((currentQuestion + 1) / answers?.length) * 100;

  useEffect(() => {
    setAnswers(result?.answers || []);
  }, [result]);

  return (
    <div className="relative w-full overflow-hidden">
      <div className=" bg-black bg-opacity-80 flex flex-col items-center justify-center">
        {isProgress ? (
          <>
            <div className=" bg-white flex items-center justify-center w-full">
              <div className="w-full mx-auto p-2">
                <div className="relative w-full h-2 bg-gray-200 rounded">
                  <div
                    className="absolute top-0 h-2 bg-blue-600 rounded"
                    style={{ width: `${progress}%` }}
                  ></div>
                </div>
                <div className="flex justify-center items-center">
                  <div className="text-gray-500 mt-2">
                    Question {currentQuestion + 1} / {answers?.length}
                  </div>
                </div>

                <div className="">
                  <img
                    src={answers[currentQuestion]?.thumbnail || placeholder}
                    alt=""
                    height={400}
                    width={600}
                    className="w-full aspect-[5/3] object-fill"
                  />
                  <h2 className="text-2xl font-bold mb-4">
                    {answers[currentQuestion]?.question}
                  </h2>
                  <div className="flex w-full">
                    {answers[currentQuestion]?.choices?.map(
                      (item: any, index: number) => (
                        <button
                          key={index}
                          className={`w-1/2 flex justify-between items-center px-1 py-1 mx-2 border rounded text-white  transition duration-200 ${
                            answers[currentQuestion]?.isCorrect
                              ? item?.isCorrect
                                ? "bg-green-500"
                                : ""
                              : item?.isCorrect
                              ? "bg-red-500"
                              : "bg-green-500"
                          }`}
                        >
                          <div className="w-6 font-bold">
                            {index === 0 ? "A" : "B"}
                          </div>
                          <div className="bg-white rounded text-black flex-1 py-1">
                            {item?.choice}
                          </div>
                          {item?.isCorrect ? (
                            <div className="w-6 flex justify-center">
                              <FaCheck />
                            </div>
                          ) : null}
                        </button>
                      )
                    )}
                  </div>
                  <div className="flex justify-between mt-6 border-t-2 border-gray-400 pt-4">
                    <button
                      onClick={handlePrevButtonClick}
                      disabled={currentQuestion === 0}
                      className={`px-4 py-2 bg-gray-500 text-white rounded w-40 ${
                        currentQuestion === 0
                          ? "opacity-20 cursor-not-allowed"
                          : "hover:bg-gray-700"
                      } transition duration-200`}
                    >
                      <FaChevronLeft />
                    </button>
                    {isImediate && (
                      <div className="py-4 flex flex-col justify-center items-center">
                        <div className="text-black text-xs">
                          Your results will be sent to your email
                        </div>
                        <div className="text-xs font-bold">{user?.email}</div>
                      </div>
                    )}

                    <button
                      onClick={handleNextButtonClick}
                      disabled={currentQuestion === answers?.length - 1}
                      className={`px-4 py-2 bg-fish text-white rounded ${
                        currentQuestion === answers?.length - 1
                          ? "opacity-20 cursor-not-allowed"
                          : "hover:bg-gray-700"
                      }  transition duration-200 w-40`}
                    >
                      <FaChevronRight />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <ScoreWidget user={user} result={result} resend={() => {}} />
        )}
      </div>
    </div>
  );
}
