import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const ScorePercent = ({ score, pathColor }: any) => {
  return (
    <div style={{ width: '90px', height: '90px' }}>
      <CircularProgressbar
        value={score}
        text={`${score}%`}
        styles={buildStyles({
          textColor: '#000',
          pathColor: pathColor,
          trailColor: '#d6d6d6',
          textSize: 30,
        })}
      />
    </div>
  );
};

export default ScorePercent;
