import { QUIZ_BASE_URL } from "../utils/urls";

const fetchData = async ({  uri }: any) => {
  try {
    const response = await fetch(`${QUIZ_BASE_URL}/${uri}`, {
      method: "GET",
    });

    if (!response.ok) {
      const errorData = await response.json();
      return Promise.reject(errorData.message || "Something went wrong");
    }

    return await response.json();
  } catch (error) {
    return Promise.reject("Network error");
  }
};

export default fetchData;