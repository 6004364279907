import { Form, Table } from "antd";
import { useState } from "react";

import { useQuery } from "@tanstack/react-query";
import Something from "../../../components/shared/Something";
import BackNav from "../../../components/toolbars/BackNav";
import { handleFetchEnrollments } from "../../../api/enrollments";
import { FaRegCircleCheck, FaRegCircleXmark } from "react-icons/fa6";
import { RiDeleteBackLine } from "react-icons/ri";

import ReverseEnroll from "./components/ReverseEnroll";

export default function Enrollments() {
  const [selected, setSelected] = useState<any>({});
  const [reverseModal, setReverseModal] = useState<boolean>(false);

  const [pagination, setPagination] = useState<any>({
    page: 1,
    limit: 10,
    total: 0,
  });

  const {
    data: enrollments,
    refetch,
    isLoading,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["enrollments", pagination.page, pagination.limit],
    queryFn: () =>
      handleFetchEnrollments({
        page: pagination.page,
        limit: pagination.limit,
      }),
  });

  const handlePageChange = (e: any) => {
    setPagination((prev: any) => ({
      ...prev,
      page: e.current,
      limit: e.pageSize,
    }));
  };

  const columns = [
    {
      title: "S/N",
      width: 40,
      render: (record: any, text: any, index: any) => (
        <span className="text-gray-700 ml-2">
          {pagination?.page * pagination?.limit - pagination?.limit + index + 1}
        </span>
      ),
    },
    {
      title: "NAME",
      width: 140,
      render: (record: any) => {
        return (
          <span className="text-gray-700">{record?.user?.name || "-"}</span>
        );
      },
    },
    {
      title: "EMAIL",
      width: 120,
      render: (record: any) => {
        return (
          <div className="text-gray-700">{record?.user?.email || "-"}</div>
        );
      },
    },
    {
      title: "QUIZ",
      width: 140,
      render: (record: any) => (
        <span className="text-gray-700">{record?.quiz?.title || "-"}</span>
      ),
    },
    {
      title: "STATE",
      width: 90,
      render: (record: any) => (
        <span className="text-gray-700 capitalize">
          {record?.status || "-"}
        </span>
      ),
    },

    {
      title: "STATUS",
      width: 60,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.isClosed ? (
            <div className="text-green-600 flex">
              <div className="flex items-center">
                <FaRegCircleCheck size={18} />{" "}
                <span className="mx-1">Finished</span>
              </div>
            </div>
          ) : (
            <div className="text-red-600 flex">
              <div className="flex items-center">
                <FaRegCircleXmark size={18} />{" "}
                <span className="mx-1">Not Finished</span>
              </div>
            </div>
          )}
        </span>
      ),
    },
    {
      title: "ACTION",
      width: 100,
      render: (record: any) => (
        <div className="flex justify-end">
          <button
            onClick={() => {
              setSelected({
                ...record,
              });
              setReverseModal(true);
            }}
            className="flex justify-center items-center text-gray-700 border rounded-md p-2 bg-red-100 hover:bg-red-200 cursor-pointer"
          >
            <RiDeleteBackLine color="red" />
          </button>
        </div>
      ),
    },
  ];

  const [emailForm] = Form.useForm();

  const handleRefetch = () => {
    refetch();
  };

  return (
    <div className="bg-gray-100">
      <BackNav link={`/admin`} />
      <div className="bg-white m-1">
        <div className="flex justify-between border-b p-2">
          <div className="">
            <div className="font-bold">Enrollments</div>
            <div className="text-gray-600">Manage Enrollments Details</div>
          </div>
        </div>
        {isError ? (
          <Something refetch={refetch} isFetching={isFetching} />
        ) : (
          <div className="">
            <Table
              size="small"
              dataSource={enrollments?.records}
              columns={columns}
              scroll={{ y: 660, x: 0 }}
              rowKey="id"
              bordered
              pagination={{
                current: pagination?.page,
                pageSize: pagination?.limit,
                total: pagination?.total,
              }}
              onChange={(e: any) => handlePageChange(e)}
              loading={isLoading}
            />
          </div>
        )}
      </div>

      <ReverseEnroll
        selected={selected}
        isVisible={reverseModal}
        setVisible={setReverseModal}
        refetch={handleRefetch}
        pagination={pagination}
      />
    </div>
  );
}
