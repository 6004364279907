import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ScorePercent from "./ScorePercent";
import { SlRefresh } from "react-icons/sl";
import ScoreAnswers from "./ScoreAnswers";

export default function ScoreWidget({ result, user, resend, isPending }: any) {
  const [status, setStatus] = useState("submitting");
  const [displayContent, setDisplayContent] = useState(false);

  let pathColor = "#4caf50";
  let message =
    "You did it! You have just aced your latest quiz, and we are thrilled to celebrate your achievement.";

  if (result?.score < 25) {
    pathColor = "#f44336";
    message =
      "Great effort! You've completed the quiz, and we're proud of your progress. Keep up the hard work!";
  } else if (result?.score < 50) {
    pathColor = "#ffc107";
    message =
      "Don't be discouraged! You've taken an important step by completing the quiz. Keep practicing, and you'll improve in no time.";
  }

  useEffect(() => {
    const changeStatus = async () => {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      setStatus("calculating");

      await new Promise((resolve) => setTimeout(resolve, 2000));
      setStatus("preparing");

      await new Promise((resolve) => setTimeout(resolve, 2000));
      setStatus("completed");
      setDisplayContent(true);
    };

    changeStatus();
  }, []);

  return (
    <div className="bg-white px-4 py-10 rounded-xl w-1/3 flex flex-col items-center justify-center">
      {displayContent ? (
        <div className="flex flex-col justify-center items-center">
          <div className="mb-6 flex flex-col justify-center items-center">
            <ScorePercent score={result?.score || 0} pathColor={pathColor} />
            <div className="font-bold text-fish text-3xl">{user?.name}</div>
            <div className="font-bold text-center text-lg">
              You got {result?.score}% on your Quiz.
            </div>
            <div className="flex flex-col items-center text-gray-500 w-1/2 text-center">
              <div>{message}</div>
            </div>

            <ScoreAnswers result={result} user={user} isImediate={true} />
          </div>
          <Link
            to="/"
            className="flex justify-center items-center bg-fish w-full py-2 rounded-lg text-white"
          >
            Thanks
          </Link>
        </div>
      ) : (
        <div className="flex flex-col">
          <div className="py-16 flex justify-center items-center">
            {status === "submitting" && (
              <div className="flex flex-col items-center ">
                <SlRefresh className="animate-spin text-3xl" /> Submitting
                Quiz...
              </div>
            )}
            {status === "calculating" && (
              <div className="flex flex-col items-center ">
                <SlRefresh className="animate-spin text-3xl" /> Calculating
                Score...
              </div>
            )}
            {status === "preparing" && (
              <div className="flex flex-col items-center ">
                <SlRefresh className="animate-spin text-3xl" /> Preparing
                Results...
              </div>
            )}
          </div>
          <button
            onClick={() => resend()}
            className="flex justify-center items-center bg-fish w-full py-2 rounded-lg text-white"
          >
            Resend
          </button>
        </div>
      )}
    </div>
  );
}
