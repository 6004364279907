import { Form, Table } from "antd";
import { useState } from "react";
import { IoEyeOutline } from "react-icons/io5";

import { useQuery } from "@tanstack/react-query";
import Something from "../../../components/shared/Something";
import BackNav from "../../../components/toolbars/BackNav";
import { handleFetchResults } from "../../../api/results";
import Moment from "react-moment";
import { Link } from "react-router-dom";

export default function Results() {
  const [selected, setSelected] = useState<any>({});

  const [pagination, setPagination] = useState<any>({
    page: 1,
    per_page: 20,
    total_pages: 1,
    total_count: 0,
  });

  const {
    data: results,
    refetch,
    isLoading,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["results"],
    queryFn: handleFetchResults,
  });

  const [emailForm] = Form.useForm();

  const columns = [
    {
      title: "S/N",
      width: 60,
      render: (record: any, text: any, index: any) => (
        <span className="text-gray-700 ml-2">
          {pagination?.page * pagination?.per_page -
            pagination?.per_page +
            index +
            1}
        </span>
      ),
    },
    {
      title: "Name",
      width: 120,
      render: (record: any) => {
        return (
          <span className="text-gray-700">{record?.user?.name || "-"}</span>
        );
      },
    },
    {
      title: "Score",
      width: 140,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.score ? <span>{record?.score}%</span> : "-"}{" "}
        </span>
      ),
    },
    {
      title: "Submitted",
      width: 90,
      render: (record: any) => (
        <div className="flex items-center text-gray-700">
          <Moment format="MMM Do, YYYY">{record?.submittedAt}</Moment>
          <div className="mx-1">
            [<Moment fromNow>{record?.submittedAt}</Moment>]
          </div>
        </div>
      ),
    },

    {
      title: "Action",
      width: 100,
      render: (record: any) => (
        <div className="flex justify-end">
          <Link
            to={`/admin/results/${record?._id}`}
            className="flex justify-center items-center text-yellow-700 border rounded-md p-2 bg-yellow-100 hover:bg-yellow-200 cursor-pointer"
          >
            <IoEyeOutline /> <span className="mx-2 text-xs">View Result</span>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <div className="bg-gray-100">
      <BackNav link={`/admin`} />
      <div className="bg-white m-1">
        <div className="flex justify-between border-b p-2">
          <div className="">
            <div className="font-bold">Results</div>
            <div className="text-gray-600">Manage Results Details</div>
          </div>
        </div>
        {isError ? (
          <Something refetch={refetch} isFetching={isFetching} />
        ) : (
          <div className="">
            <Table
              size="small"
              dataSource={results}
              columns={columns}
              scroll={{ y: 660, x: 0 }}
              rowKey="id"
              bordered
              pagination={{
                current: pagination?.page,
                pageSize: pagination?.per_page,
                total: 20,
              }}
              loading={isLoading}
            />
          </div>
        )}
      </div>
    </div>
  );
}
