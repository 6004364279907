import { IoLogoBuffer } from "react-icons/io5";
import logo from "../../assets/images/logo_white.png";

import { Link } from "react-router-dom";

export default function QuizHeader({ user }: any) {
  return (
    <div className="h-16 bg-fish flex justify-between items-center w-1/2">
      <Link to={"/"} className="flex items-center justify-center w-1/3">
        <div className="flex items-center">
          <IoLogoBuffer color="#FFFFFF" size={40} />
          <img src={logo} alt="" width={110} />
        </div>
        <div className="border-l text-white text-2xl mx-2 px-2 font-thin">
          CYBER
        </div>
      </Link>
      <div className="flex justify-center items-center text-white text-3xl w-1/3">
        {user && (
          <div className="flex justify-center items-center uppercase text-lg font-bold mt-4">
            {user?.name}
          </div>
        )}
      </div>
      <Link to={"/"} className="flex  items-center justify-end text-white w-1/3">
        <div className="mx-2 text-2xl">KARIMJEE</div>
      </Link>
    </div>
  );
}
