import placeholder from "../../../../assets/images/placeholder.png";
import { Link } from "react-router-dom";
import { IoCalendarOutline } from "react-icons/io5";
import Moment from "react-moment";
import { LuPin, LuPinOff } from "react-icons/lu";
import getMarks from "../../../../utils/getMarks";
export default function QuizWidget({
  item,
  index,
  handleShow,
  handleThumbnail,
}: any) {
  return (
    <div className="flex my-2 border p-2" key={index}>
      <button
        onClick={() => handleThumbnail(item)}
        className="bg-gray-200 w-40 h-36"
      >
        <img
          src={item?.thumbnail || placeholder}
          alt=""
          className="w-full aspect-[4/4] object-fill"
          style={{ width: "100%" }}
        />
      </button>
      <Link to={`/admin/quizzes/${item?._id}`} className="bg-gray-200 p-2 border-l border-gray-300">
        <div className="font-bold">
          <span>{item?.enrolled}</span> Enrollment(s)
        </div>

        <div className="flex w-40">
          <div className="flex flex-col">
            <div className="flex items-center">
              <div className="w-6 h-6  border-black flex justify-center items-center">
                <IoCalendarOutline />
              </div>
              <div className="text-sm">
                <div>
                  <Moment format="Do MMM, YYYY">{item?.startDate}</Moment>
                </div>
              </div>
            </div>
            <div className="border-l border-gray-400 h-6"></div>
            <div className="flex items-center">
              <div className="w-6 h-6 border-black flex justify-center items-center">
                <IoCalendarOutline />
              </div>
              <div className="text-sm">
                <div>
                  <Moment format="Do MMM, YYYY">{item?.endDate}</Moment>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
      <div className="flex flex-col w-full">
        <div className="border-b p-2 font-bold flex justify-between items-center">
          <Link to={`/admin/quizzes/${item?._id}`} className="">
            QUIZ: {item?.title}
          </Link>
          <button onClick={() => handleShow(item)} className="flex">
            <div className="mx-2">
              {item?.isShowed ? (
                <LuPin color="#16a34a" size={30} />
              ) : (
                <LuPinOff color="#4b5563" size={30} />
              )}
            </div>
            <div
              style={{
                color: item?.isShowed ? "#16a34a" : "#4b5563",
              }}
            >
              Active
            </div>
          </button>
        </div>
        <Link to={`/admin/quizzes/${item?._id}`} className="flex flex-col">
          <div className="px-2">{item?.description}</div>
          <div className="px-2 text-gray-600 text-left">
            There are{" "}
            <span className="font-bold">{getMarks(item).questions}</span>{" "}
            Questions in total, each caring{" "}
            <span className="font-bold">{getMarks(item).marks}</span> Marks.
          </div>
        </Link>
      </div>
    </div>
  );
}
