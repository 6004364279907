import { Button, Modal, Form, Row, Col, Input, message } from "antd";
import { useEffect, useState } from "react";
import { BsCheck2Circle } from "react-icons/bs";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleUpdateQues } from "../../../../api/quizzes";
import getBase64 from "../../../../utils/getBase64";

export default function UpdateQues({ id, ques, isVisible, setVisible }: any) {
  const queryClient = useQueryClient();
  const [isSelected, setIsSelected] = useState(false);
  const [selectedFile, setSelectedFile]: any = useState(null);
  const [form] = Form.useForm();
  const [selected, setSelected] = useState<number>(0);

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const options = [
    { id: 1, name: "Phishing" },
    { id: 0, name: "Not Phishing" },
  ];

  const { mutate, isPending } = useMutation({
    mutationFn: handleUpdateQues,
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      setSelectedFile(null);
      setIsSelected(false);
      queryClient.invalidateQueries({ queryKey: [id, "quizzes"] });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    let base64,
      type,
      size = null;

    if (selectedFile) {
      base64 = await getBase64(selectedFile);
      type = selectedFile?.type?.slice(selectedFile?.type - 3);
      size = selectedFile?.size;
    }

    const { question, description, hint } = await form.getFieldsValue();
    const data = {
      question,
      description,
      hint,
      thumbnail: base64,
      choices: [
        { choice: "Phishing", isCorrect: selected === 1 },
        { choice: "Not Phishing", isCorrect: selected === 0 },
      ],
    };
    await mutate({ _id: id, questionId: ques._id, data });
  };

  const clearForm = async () => {
    form.resetFields();
    setSelectedFile(null);
    setIsSelected(false);
  };

  useEffect(() => {
    form.setFieldsValue({
      question: ques?.question,
      description: ques?.description,
      hint: ques?.hint,
      thumbnail: ques?.thumbnail,
      choices: ques?.choices,
    });

    setSelected(ques?.choices[0].isCorrect ? 1 : 0);
  }, [ques]);

  return (
    <Modal
      open={isVisible}
      title="Update Question"
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="updateQuestion"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isPending}
        >
          Update
        </Button>,
      ]}
    >
      <Form
        id="updateQuestion"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="question"
              label={"Question"}
              rules={[
                {
                  required: true,
                  message: "Please enter question",
                },
              ]}
            >
              <Input type="text" placeholder="Question" />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="hint"
              label={"Hint"}
              rules={[
                {
                  required: false,
                  message: "Please enter hint",
                },
              ]}
            >
              <Input type="text" placeholder="Hint" />
            </Form.Item>
          </Col>
        </Row>

        <div className="flex flex-col justify-center items-center bg-gray-100 h-32 rounded-md border">
          <div className="flex flex-col justify-center items-center">
            {isSelected ? (
              <div className="flex flex-col justify-center items-center">
                <BsCheck2Circle color={"#16a34a"} size={30} />
                <div className="text-gray-600 mt-2">Image Selected</div>
                <div className="text-gray-500">{selectedFile?.name}</div>
              </div>
            ) : (
              <Form.Item
                name="file"
                label="Upload Question Image ( Optional )"
                rules={[
                  {
                    required: false,
                    message: "Please select file",
                  },
                ]}
              >
                <Input
                  type="file"
                  placeholder="Question Image"
                  onChange={changeHandler}
                />
              </Form.Item>
            )}
          </div>
        </div>

        <div className="flex flex-col mt-4">
          <div className="">Choose the correct Answer</div>
          <div className="flex mt-1">
            {options?.map((item: any, index: number) => (
              <button
                onClick={() => setSelected(item?.id)}
                type="button"
                className={`border w-1/2 p-2 ${
                  selected === item?.id ? "bg-green-200" : ""
                }`}
                key={index}
              >
                {item?.name}
              </button>
            ))}
          </div>
        </div>
      </Form>
    </Modal>
  );
}
