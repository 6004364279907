import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Form, message } from "antd";
import { useContext, useEffect, useState } from "react";
import bg from "../../assets/images/bg.png";
import kg_logo_white from "../../assets/images/kg_logo_white.png";
import {
  handleSendEmail,
  handleShowedQuiz,
  handleStartQuiz,
} from "../../api/quizzes";
import { PiUserSwitchThin } from "react-icons/pi";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo_white.png";
import getMarks from "../../utils/getMarks";
import { AuthContext } from "../../contexts/AuthContext";
import Something from "../../components/shared/Something";
import Error from "../../components/shared/Error";
import moment from "moment";
import Moment from "react-moment";

export default function Welcome() {
  const { signinUser } = useContext(AuthContext);
  const [email, setEmail] = useState<string>("");
  const queryClient = useQueryClient();
  const [err, setErr] = useState<boolean>(false);
  const [form] = Form.useForm();

  const {
    data: quiz,
    refetch,
    isError,
    isFetching,
  } = useQuery({
    queryKey: ["quiz"],
    queryFn: handleShowedQuiz,
  });

  const navigate = useNavigate();

  const { mutate: sendEmail } = useMutation({
    mutationFn: handleSendEmail,
    onSuccess: () => {},
    onError: (error) => {},
  });

  const handleSendMail = async (user: any) => {
    const date = moment(quiz?.endDate).format("Do MMM, YYYY");
    const data = {
      to: user?.email,
      subject: `QUIZ: ${quiz?.title}`,
      text: `
      Hello ${user?.name},
      Please make sure to complete this quiz before ${date}.
      
      Thanks.`,
    };
    await sendEmail({ data });
  };

  const { mutate, isPending: isStarting } = useMutation({
    mutationFn: handleStartQuiz,
    onSuccess: (data) => {
      message.success("Started Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["generics"] });
      const payload = { user: data };
      signinUser(payload);

      navigate(`/quiz/${quiz?._id}`);
      handleSendMail(data);
    },
    onError: (error) => {
      message.error(`${error}`);
    },
  });

  const onSubmit = async () => {
    if (email === "") {
      message.error("Please, Enter your valid company email.");
      setErr(true);
      return;
    }

    const data = {
      email,
    };
    mutate({ _id: quiz?._id, data });
  };

  useEffect(() => {
    if (!quiz && !isFetching && !isError) {
      refetch();
    }
  }, [quiz, isFetching, isError, refetch]);

  return (
    <div className="relative w-full h-screen overflow-hidden">
      <video
        className="absolute hidden sm:block top-0 left-0 w-full h-full object-cover"
        src="https://ik.imagekit.io/ttltz/cybersecurity/quiz/video_5fSvRpaLu.mp4?updatedAt=1718008696191"
        autoPlay
        loop
        muted
      />
      <img
        src={bg}
        alt=""
        className="absolute block sm:hidden top-0 left-0 w-full h-full object-cover"
      />
      <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-start justify-center">
        <div className="text-center text-white p-4 mt-8">
          <div className="flex flex-col items-center">
            <img src={kg_logo_white} alt="" width={140} />
            {/* <div className="text-5xl sm:text-7xl font-bold">KARIMJEE</div> */}
            {/* <h1 className="text-2xl sm:text-4xl font-bold mb-4">
            CYBER SECURITY
            </h1> */}
            <div className="text-2xl">Take The Next Step In Your</div>
            <div className="mb-2 font-bold">Cybersecurity Awareness.</div>
          </div>
          <div className="">
            {isError ? (
              <Something refetch={refetch} isFetching={isFetching} />
            ) : (
              <div className="">
                {quiz ? (
                  <div className="flex flex-col items-center justify-center">
                    <div className="bg-white p-4 w-full sm:w-3/5 md:w-2/5 lg:w-2/4">
                      <div className=" mb-4 border-b pb-2 grid grid-cols-12">
                        {/* <div className="cols-span-12 sm:col-span-3 hidden sm:block">
                          <img
                            src={quiz?.thumbnail || placeholder}
                            alt=""
                            height={450}
                            width={440}
                            className="w-full aspect-[4/4] object-fill"
                            style={{ width: "100%" }}
                          />
                        </div> */}
                        <div className="col-span-12 sm:col-span-12">
                          <div className="flex flex-col justify-start items-start mx-4">
                            <div className="flex justify-center items-center w-full">
                              <div className="text-3xl text-black text-center flex justify-center  border-b-2 border-black mb-2">
                                {quiz?.title}
                              </div>
                            </div>
                            <div className="text-black text-left">
                              {quiz?.description}
                            </div>

                            <div
                              className=" text-gray-600 text-left mt-3 italic"
                              style={{ fontSize: 13 }}
                            >
                              There are{" "}
                              <span className="font-bold">
                                {getMarks(quiz).questions}
                              </span>{" "}
                              Questions in total, each caring{" "}
                              <span className="font-bold">
                                {getMarks(quiz).marks}
                              </span>{" "}
                              Marks.
                            </div>

                            <div className="flex  items-center text-black text-xs mt-3">
                              <div className="text-left font-bold text-red-600">
                                DEADLINE :{" "}
                              </div>
                              <div className="flex items-center uppercase mx-1">
                                <Moment format="Do MMMM YYYY">
                                  {quiz?.endDate}
                                </Moment>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-col">
                        <div className="mb-4">
                          <div className="text-black mb-1">
                            Enter Your Company Email to start
                          </div>
                          <input
                            type="email"
                            onChange={(e) => {
                              setErr(false);
                              setEmail(e.target.value);
                            }}
                            placeholder="Enter your email"
                            className={`bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 ${
                              err ? "border-red-500 bg-red-100" : ""
                            }`}
                          />
                        </div>
                        <button
                          onClick={() => onSubmit()}
                          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        >
                          {isStarting ? "Starting ...." : "Start Quiz"}
                        </button>
                      </div>
                    </div>
                    <Link
                      to={"/admin"}
                      className="flex  items-center text-white"
                    >
                      <PiUserSwitchThin size={24} />
                      <div className="mx-2">Admin Portal</div>
                    </Link>
                  </div>
                ) : (
                  <Error
                    refetch={refetch}
                    isFetching={isFetching}
                    error="No Active Quiz For Now."
                  />
                )}
              </div>
            )}
          </div>

          <div className="flex justify-center items-center">
            <div className="absolute bottom-10 justify-center items-center ">
              <div className=" flex flex-col items-center">
                <div className="text-xs">Powered By</div>
                <img src={logo} alt="" width={50} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
