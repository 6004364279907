import { message } from "antd";
import { useState } from "react";
import { FiEdit } from "react-icons/fi";
import { GoCheck } from "react-icons/go";
import { useParams } from "react-router";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import CreateQues from "./components/CreateQues";
import Something from "../../../components/shared/Something";
import BackNav from "../../../components/toolbars/BackNav";
import { handleFetchQuiz, handleShowQuiz } from "../../../api/quizzes";
import QuizWidget from "./components/QuizWidget";
import placeholder from "../../../assets/images/placeholder.png";
import UploadQuesThumb from "./components/UploadQuesThumb";
import UploadQuizThumb from "./components/UploadQuizThumb";
import Enrollments from "./components/Enrollments";
import DeleteQuiz from "./components/DeleteQuiz";
import UpdateQuiz from "./components/UpdateQuiz";
import UpdateQues from "./components/UpdateQues";
import DeleteQues from "./components/DeleteQues";
import NoResult from "../../../components/shared/NoResult";
import { RiDeleteBin3Line } from "react-icons/ri";

export default function Quiz() {
  const [deleteQuizModal, setDeleteQuizModal] = useState<boolean>(false);
  const [updateQuizModal, setUpdateQuizModal] = useState<boolean>(false);
  const [uploadQuizModal, setUploadQuizModal] = useState<boolean>(false);

  const [uploadQuesModal, setUploadQuesModal] = useState<boolean>(false);
  const [createQuesModal, setCreateQuesModal] = useState<boolean>(false);
  const [updateQuesModal, setUpdateQuesModal] = useState<boolean>(false);
  const [deleteQuesModal, setDeleteQuesModal] = useState<boolean>(false);

  const [selectedQues, setSelectedQues] = useState<any>(null);
  const [selectedQuiz, setSelectedQuiz] = useState<any>(null);
  const queryClient = useQueryClient();

  const { id } = useParams();

  const {
    data: quiz,
    refetch,
    isFetching,
    isError,
  } = useQuery({
    queryKey: [id, "quizzes"],
    queryFn: () => handleFetchQuiz(id),
  });

  const handleQuizThumbnail = (item: any) => {
    setSelectedQuiz(item);
    setUploadQuizModal(true);
  };

  const { mutate } = useMutation({
    mutationFn: handleShowQuiz,
    onSuccess: () => {
      message.success("Updated Successfully");
      queryClient.invalidateQueries({ queryKey: ["quizzes"] });
      queryClient.invalidateQueries({ queryKey: [id, "quizzes"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const handleShow = (quiz: any) => {
    mutate({ data: {}, _id: quiz?._id });
  };

  const handleRefetch = () => {
    refetch();
  };

  return (
    <div className="bg-gray-100">
      <BackNav link={`/admin/quizzes`} />
      <div className="bg-white m-1">
        <div className="flex justify-between border-b p-2">
          <div className="">
            <div className="font-bold">Quizzes</div>
            <div className="text-gray-600">Manage Quizzes Details</div>
          </div>

          <div className="flex">
            <button
              onClick={() => {
                setSelectedQuiz(quiz);
                setUpdateQuizModal(true);
              }}
              className="flex mx-2 justify-center items-center border px-4 py-1 h-10 hover:bg-gray-100"
            >
              <FiEdit />
              <span className="mx-2">Update</span>
            </button>
            <button
              onClick={() => {
                setSelectedQuiz(quiz);
                setDeleteQuizModal(true);
              }}
              className="flex mx-2 justify-center items-center border bg-red-100 px-4 py-1 h-10 hover:bg-red-200"
            >
              <RiDeleteBin3Line color="#FF0000" size={20} />
            </button>
          </div>
        </div>
        {isError ? (
          <Something refetch={refetch} isFetching={isFetching} />
        ) : (
          <div className="p-4">
            <QuizWidget
              item={quiz}
              index={quiz?._id}
              key={quiz?._id}
              handleShow={handleShow}
              handleThumbnail={handleQuizThumbnail}
            />

            <div className="">
              <div className="flex justify-between items-center border-b-2 pb-2 mb-2">
                <div className="font-bold">QUESTIONS</div>
                <button
                  onClick={() => setCreateQuesModal(true)}
                  className="flex mx-2 justify-center items-center border px-4 py-1 h-10 hover:bg-gray-100"
                >
                  <FiEdit />
                  <span className="mx-2">Add Question</span>
                </button>
              </div>
              {quiz?.questions?.length > 0 ? (
                <div className="">
                  <div className="grid grid-cols-12 gap-2">
                    {quiz?.questions?.map((item: any, index: number) => (
                      <div
                        className="col-span-12 sm:col-span-4 border"
                        key={index}
                      >
                        <button
                          onClick={() => {
                            setSelectedQues(item);
                            setUploadQuesModal(true);
                          }}
                        >
                          <img
                            src={item?.thumbnail || placeholder}
                            alt=""
                            className="w-full aspect-[4/3] object-fill border-b"
                            style={{ width: "100%" }}
                          />
                        </button>
                        <div className="flex justify-between ">
                          <div className="flex flex-col mx-2">
                            <div>{item?.question}</div>
                            <div className="text-gray-600">
                              Hint: {item?.hint}
                            </div>
                          </div>

                          <div className="flex">
                            <button
                              onClick={() => {
                                setSelectedQues(item);
                                setUpdateQuesModal(true);
                              }}
                              className="flex mx-2 justify-center items-center border bg-yellow-100 h-8 p-2  hover:bg-yellow-200"
                            >
                              <FiEdit />
                            </button>
                            <button
                              onClick={() => {
                                setSelectedQues(item);
                                setDeleteQuesModal(true);
                              }}
                              className="flex mx-2 justify-center items-center border bg-red-100 h-8 p-2  hover:bg-red-200"
                            >
                              <RiDeleteBin3Line color="#FF0000" size={20} />
                            </button>
                          </div>
                        </div>
                        <div className="flex mt-1 mb-2 mx-2">
                          {item?.choices?.map((item: any, index: number) => (
                            <div
                              className={`w-1/2 p-2 m-1 border flex justify-center items-center ${
                                item?.isCorrect ? "bg-green-600 text-white" : ""
                              }`}
                              key={index}
                            >
                              <div className="w-6 h-6 border mx-2 flex justify-center items-center">
                                {item?.isCorrect ? <GoCheck /> : ""}
                              </div>
                              <div className="">{item?.choice}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <NoResult title="No Questions Found" />
              )}
            </div>

            <div className="my-4">
              <Enrollments id={quiz?._id} />
            </div>
          </div>
        )}
      </div>

      <UploadQuizThumb
        id={selectedQuiz?._id}
        isVisible={uploadQuizModal}
        setVisible={setUploadQuizModal}
      />

      <UploadQuesThumb
        id={id}
        isVisible={uploadQuesModal}
        setVisible={setUploadQuesModal}
        questionId={selectedQues?._id}
        refetch={handleRefetch}
      />

      <UpdateQuiz
        quiz={selectedQuiz}
        isVisible={updateQuizModal}
        setVisible={setUpdateQuizModal}
        refetch={handleRefetch}
      />

      <DeleteQuiz
        quiz={selectedQuiz}
        isVisible={deleteQuizModal}
        setVisible={setDeleteQuizModal}
      />

      <CreateQues
        id={id}
        isVisible={createQuesModal}
        setVisible={setCreateQuesModal}
        refetch={handleRefetch}
      />

      <UpdateQues
        id={id}
        ques={selectedQues}
        isVisible={updateQuesModal}
        setVisible={setUpdateQuesModal}
        refetch={handleRefetch}
      />

      <DeleteQues
        id={id}
        ques={selectedQues}
        isVisible={deleteQuesModal}
        setVisible={setDeleteQuesModal}
      />
    </div>
  );
}
