import fetchData from "../services/fetchData";

const handleFetchResults = async () => {
  const uri = `v1/results`;
  const response = fetchData({ uri });
  return response;
};

const handleFetchResult = async (_id: any) => {
  const uri = `v1/results/${_id}`;
  const response = fetchData({ uri });
  return response;
};

const handleFetchResultsCount = async (id: any) => {
  const uri = `v1/results/count/all`;
  const response = fetchData({ uri });
  return response;
};

export { handleFetchResult, handleFetchResults, handleFetchResultsCount };
