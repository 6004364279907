import { useQuery } from "@tanstack/react-query";
import { TbUsers } from "react-icons/tb";
import { Link } from "react-router-dom";
import { handleFetchUsersCount } from "../../api/users";

export default function ManageUsers() {
  const { data: count } = useQuery({
    queryKey: ["userCount"],
    queryFn: handleFetchUsersCount,
  });
  return (
    <Link
      to={`/admin/users`}
      className={`col-span-6 sm:col-span-4 lg:col-span-3 bg-white p-4 rounded-md mx-1 mt-1 sm:mx-1 sm:my-1`}
      style={{
        backgroundColor: "#4A71FF",
        color: "#FFFFFF",
      }}
    >
      <div className="flex items-center justify-center">
        <div className="text-3xl sm:text-6xl border-r font-bold mx-2 sm:mx-4 px-2 sm:px-4">{count}</div>
        <div className="flex flex-col justify-center items-center">
          <div className="">
            <TbUsers color="#ffffff" size={40} />
          </div>
          <div className="">Users</div>
        </div>
      </div>
    </Link>
  );
}
